import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";

export const getQuestionImage = async (imageId: string) => {
  const img = ref(storage, `questionImages/${imageId}.jpg`);
  return getDownloadURL(img)
    .then((url) => url)
    .catch(() => {
      const img2 = ref(storage, `questionImages/${imageId}.png`);
      return getDownloadURL(img2).then((url) => url);
    });
};

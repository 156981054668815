/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { useFetch } from "../../helpers/useFetch";
import HostView from "./HostView/HostView";
import PlayerView from "./PlayerView/PlayerView";
import { hostTheme, playerTheme } from "../../helpers/constants";
import * as H from "./helpers";
import "./styles.scss";

const RoomPage = () => {
  const { ID: roomID } = useParams() as { ID: string };
  const { state } = useLocation<{ user: string }>();
  const userID = state?.user;

  const { room, categories } = useFetch();
  process.env.NODE_ENV !== "production" && console.log(roomID, room);

  const isHost = H.isHost(userID, room?.hostUserID);

  const history = useHistory();

  useEffect(() => {
    if (!userID) {
      history.push(`/`, { roomID });
    }
  }, []);

  return (
    <>
      {isHost ? (
        <ThemeProvider theme={hostTheme}>
          <HostView room={room} categories={categories} />
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={playerTheme}>
          <PlayerView room={room} />
        </ThemeProvider>
      )}
    </>
  );
};

export default RoomPage;

import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ReactDOM from "react-dom";
import IntroPage from "./modules/IntroPage/IntroPage";
import RoomPage from "./modules/RoomPage/RoomPage";
import "./index.css";
import "./styles.scss";

export const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <IntroPage />
        </Route>
        <Route path="/:ID">
          <RoomPage />
        </Route>
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

import React, { useEffect, useState } from "react";
import { IRoom } from "../../helpers/types";
import ScoreBoard from "../ScoreBoard/ScoreBoard";
import Button from "@mui/material/Button";
import FlagIcon from "@mui/icons-material/Flag";
import * as API from "../../api/roomAPI";
import "./styles.scss";
import "animate.css";

interface FinalScoreBoardProps {
  room: IRoom;
}

const FinalScoreBoard: React.FC<FinalScoreBoardProps> = ({ room }) => {
  const [finalScoreView, setFinalScoreView] = useState(1);

  useEffect(() => {
    const setScoreViewTimer = setTimeout(() => setFinalScoreView(2), 2000);
    return () => {
      clearTimeout(setScoreViewTimer);
    };
  }, []);

  const onStartAgainQuizButton = () => {
    API.playQuizAgain(room);
  };

  return (
    <div className="FinalScoreBoard">
      {finalScoreView === 1 && (
        <div>A wiec mamy koniec za chwile wyniki !!!</div>
      )}

      {finalScoreView === 2 && (
        <>
          <ScoreBoard
            userWithPoints={room.usersIn.sort((a, b) => b.points - a.points)}
          />

          <div className="FinalScoreBoard__startAgainButton">
            <Button
              className=""
              variant="outlined"
              color="info"
              size="large"
              startIcon={<FlagIcon />}
              onClick={onStartAgainQuizButton}
            >
              Zagrajmy jeszcze raz
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FinalScoreBoard;

import React, { useMemo } from "react";
import Slider from "react-slick";
import { IRoom, IUserIn } from "../../helpers/types";
import debounce from "lodash.debounce";
import * as API from "../../api/roomAPI";
import * as H from "../../modules/RoomPage/helpers";
import "./styles.scss";

interface SliderAvatarsProps {
  room: IRoom;
  user: IUserIn;
}

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 3,
  speed: 500,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        centerPadding: "20px",
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        centerPadding: "200px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        centerPadding: "150px",
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        centerPadding: "130px",
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        centerPadding: "110px",
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        centerPadding: "90px",
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        centerPadding: "70px",
      },
    },
  ],
};

const SliderAvatars: React.FC<SliderAvatarsProps> = ({ room, user }) => {
  const avatarsList = useMemo(
    () =>
      Array.from({ length: 10 }, (v, i) => i).map((i) => (
        <div key={i}>
          <img
            src={`media/avatars/${H.getGender(user.name)}/${i}.svg`}
            alt="avatar"
            width={140}
          />
        </div>
      )),
    [user.name],
  );

  const onChange = (avatarId: number) => {
    API.changeAvatarId(room!, user.id!, avatarId);
  };

  return (
    <div className="SliderAvatars">
      <Slider
        {...settings}
        initialSlide={user.avatarID}
        afterChange={debounce(onChange, 1000)}
      >
        {avatarsList}
      </Slider>
    </div>
  );
};

export default SliderAvatars;

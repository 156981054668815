import { useEffect, useState, useCallback } from "react";
import { IRoom, IUserWithPoints } from "./types";
import * as API from "../api/roomAPI";

export const useUserAnswerPoints = (
  roomID: string,
  room?: IRoom,
): { userWithPoints: IUserWithPoints[] } => {
  const [userWithPoints, setUserWithPoints] = useState<IUserWithPoints[]>([]);

  const calculatePoints = useCallback(() => {
    if (room) {
      const userPreviewPointsArray: IUserWithPoints[] = [];

      for (const user of room.usersIn) {
        let points = 0;

        const userAnswer = room.currentQuestion?.userAnswers?.[user.id];

        if (
          userAnswer?.name ===
          room.questions?.[room.currentQuestion.qNumber].answers[0]
        ) {
          const timeDiff =
            100 -
            Math.round((userAnswer.time - room.currentQuestion.qtime) / 100);

          if (timeDiff >= 20) {
            points = timeDiff;
          } else if (timeDiff < 20) {
            points = 10;
          }
        }

        userPreviewPointsArray.push({
          id: user.id,
          points,
        });
      }

      if (
        Object.keys(room?.currentQuestion?.userAnswers || {})?.length ===
        room?.usersIn?.length
      ) {
        API.finishQuestion(roomID);
      }

      setUserWithPoints(
        userPreviewPointsArray.sort((a, b) => b.points - a.points),
      );
    }
  }, [room, roomID]);

  useEffect(() => {
    if (room?.currentQuestion?.answers) {
      console.log('poszlo !')
      calculatePoints();
    }
  }, [calculatePoints, room?.currentQuestion?.answers]);

  useEffect(() => {
    setUserWithPoints([]);
  }, [room?.currentQuestion?.qNumber]);


  return { userWithPoints };
};

export default useUserAnswerPoints;

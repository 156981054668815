import React, { useState } from "react";
import { useParams } from "react-router-dom";
import cx from "classnames";
import { categoriesIcons } from "src/helpers/constants";
import Button from "@mui/material/Button";
import FlagIcon from "@mui/icons-material/Flag";
import { ICategories, IRoom } from "../../../../../helpers/types";
import useUserAnswerPoints from "../../../../../helpers/useUserAnswerPoints";
import QuestionScoreBoard from "../../../../../components/QuestionScoreBoard/QuestionScoreBoard";
import * as API from "../../../../../api/roomAPI";
import "./styles.scss";

interface LeftSideProps {
  room?: IRoom;
  categories?: ICategories[];
}

const LeftSide: React.FC<LeftSideProps> = ({ room, categories }) => {
  const { ID: roomID } = useParams() as { ID: string };
  const [categoryChosen, setCategoryChosen] = useState<string>();

  const { userWithPoints } = useUserAnswerPoints(roomID, room);

  const onChosenCategory = (category: string) => () => {
    setCategoryChosen(category);
    API.pushCategoryWithQuestions(roomID!, category);
  };

  const onStartQuizButton = () => {
    API.pushQuestionReq(roomID!, room!);
  };

  const isEveryPlayerReady = room?.usersIn?.every((user) => user.isReady);

  const { currentQuestion: cQ, questions } = room || {};

  return (
    <div className={cx("Left_side", { isQuizStared: room?.currentQuestion })}>
      <div className="Left_side__container">
        {!room?.currentQuestion ? (
          <>
            <div>Wybierz kategorie: </div>
            <div className="Left_side__container__category_container">
              {categories?.map((category) => {
                const Icon =
                  categoriesIcons[category.name] || categoriesIcons["default"];

                return (
                  <Button
                    key={category.name}
                    size="large"
                    variant="outlined"
                    color={
                      categoryChosen === category.name ? "info" : "primary"
                    }
                    startIcon={Icon}
                    onClick={onChosenCategory(category.name)}
                  >
                    {category.title}
                  </Button>
                );
              })}
            </div>
            <Button
              variant="outlined"
              color="success"
              size="large"
              startIcon={<FlagIcon />}
              disabled={
                !(
                  room?.usersIn &&
                  room?.usersIn?.length >= 2 &&
                  room.questions &&
                  isEveryPlayerReady
                )
              }
              onClick={onStartQuizButton}
            >
              Startujemy
            </Button>
          </>
        ) : cQ?.isFinished ? (
          <QuestionScoreBoard
            userWithPoints={userWithPoints}
            usersIn={room.usersIn}
          />
        ) : (
          <>
            {room?.currentQuestion?.imageUrl && (
              <>
                <div className="Left_side__container__question_number">
                  {cQ!.qNumber + 1}/{questions?.length}
                </div>
                <div className="Left_side__container__image_container">
                  <img
                    className="Left_side__container__image_container__image"
                    src={room?.currentQuestion?.imageUrl}
                    alt="questionImage"
                  />
                </div>
              </>
            )}
            <div
              className={`${!!cQ?.imageUrl ? "Left_side__container__question_with_image" : "Left_side__container__question"}`}
            >
              {room?.currentQuestion?.question}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeftSide;

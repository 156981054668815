import React, { useEffect, useState } from "react";
import cx from "classnames";
import difference from "lodash.difference";
import { IRoom, Traps } from "../../helpers/types";
import * as H from "../../modules/RoomPage/helpers";
import * as C from "../../helpers/constants";
import S from "./styles.module.scss";

interface TrapsNotificationProps {
  userID: string;
  room?: IRoom;
}

const TrapsNotification: React.FC<TrapsNotificationProps> = ({
  userID,
  room,
}) => {
  const [trapsToShow, setTrapsToShow] = useState<Traps[]>([]);
  const ref = React.useRef<Record<Traps, { userFromID: string }>>();
  const timerRef = React.useRef<any>();

  const traps = room?.currentQuestion?.userTraps?.[userID];

  const closeModal = () => {
    setTrapsToShow([]);
    timerRef.current = null;
  };

  useEffect(() => {
    if (traps) {
      if (ref.current) {
        const diff = difference(
          Object.keys(traps),
          Object.keys(ref.current),
        ) as Traps[];
        setTrapsToShow((prev: Traps[]) => [...prev, ...diff]);
      } else {
        setTrapsToShow(Object.keys(traps) as Traps[]);
      }
      // timerRef.current = setTimeout(() => closeModal(), 6000)
    }
    ref.current = traps;

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [traps]);

  const trapsWithValues = trapsToShow.map((trapName) => {
    const { name, avatarID } = H.getUserFromUsersIn(
      traps?.[trapName].userFromID!,
      room,
    )!;
    const { title, isPositive } = C.trapList[trapName as Traps];

    return {
      title,
      userName: name,
      userAvatar: avatarID,
      isPositive: !!isPositive,
    };
  });

  return (
    <>
      {!!trapsToShow.length && (
        <div className={S.Container} onClick={closeModal}>
          {trapsWithValues.map((trap) => (
            <div
              className={cx(
                S.TrapWrapper,
                { [S.IsPositive]: trap.isPositive },
                "animate__animated animate__backInLeft animate__delay-0s",
              )}
              key={trap.title}
            >
              <div className={S.TrapName}>{trap.title}</div>
              od
              <div className={S.UserWrapper}>
                <img
                  className={S.Avatar}
                  src={`media/avatars/${H.getGender(trap.userName)}/${trap.userAvatar}.svg`}
                  alt="avatar"
                />
                <div>{trap.userName}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default TrapsNotification;

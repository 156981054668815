import { set, ref } from "firebase/database";
// import shuffle from 'lodash.shuffle'
import { db } from "../firebase";
import { IRoom, IUserWithPoints, Traps } from "../helpers/types";
import { maxQuestionNumber, timeForAnswersShowUp } from "../helpers/constants";
import shuffle from "lodash.shuffle";
import { getDatabase, get, child } from "firebase/database";
import * as Storage from "../api/storage";
import * as C from "../helpers/constants";

export const pushCategoryWithQuestions = (roomID: string, category: string) =>
  get(child(ref(getDatabase()), `questions/${category}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const questionsShuffled10 = shuffle(snapshot.val()).splice(
          0,
          maxQuestionNumber,
        );
        set(ref(db, `/rooms/${roomID}/questions`), questionsShuffled10);
      }
    })
    .catch((error) => console.error(error));

export const pushQuestionReq = async (roomID: string, room: IRoom) => {
  const qNumber =
    room.currentQuestion?.qNumber === undefined
      ? 0
      : room.currentQuestion.qNumber + 1;

  if (qNumber === maxQuestionNumber) {
    return finishQuiz(roomID);
  }

  const question: { question: string; qNumber: number; imageUrl?: string } = {
    question: room.questions[qNumber].question,
    qNumber,
  };

  try {
    question.imageUrl = await Storage.getQuestionImage(
      room.questions[qNumber]?.imageId,
    );
  } catch (error) {}

  await set(ref(db, `/rooms/${roomID}/currentQuestion`), question);

  setTimeout(async () => {
    await set(
      ref(db, `/rooms/${roomID}/currentQuestion/answers`),
      shuffle(room.questions[qNumber].answers),
    );
    await set(
      ref(db, `/rooms/${roomID}/currentQuestion/qtime`),
      new Date().getTime(),
    );
  }, timeForAnswersShowUp);
};

export const pushAnswerReq = (answer: string, roomID: string, userID: string) =>
  set(ref(db, `/rooms/${roomID}/currentQuestion/userAnswers/${userID}`), {
    name: answer,
    time: new Date().getTime(),
  });

export const finishQuestion = (roomID: string) =>
  set(ref(db, `/rooms/${roomID}/currentQuestion/isFinished`), true);

export const pushPointsToUsersReq = (
  room: IRoom,
  roomID: string,
  userWithPoints: IUserWithPoints[],
) => {
  const usersWithNewPoints = room.usersIn.map((user) => ({
    ...user,
    points:
      user.points +
      userWithPoints!.find((userPrev) => userPrev.id === user.id)!.points,
    ownTraps: [
      ...(user.ownTraps || []),
      shuffle(Object.keys(C.trapList)).splice(0, 1),
    ],
  }));

  set(ref(db, `/rooms/${roomID}/usersIn`), usersWithNewPoints);
};

export const finishQuiz = (roomID: string) => {
  set(ref(db, `/rooms/${roomID}/isQuizEnd`), true);
};

export const playQuizAgain = (room: IRoom) =>
  set(ref(db, `/rooms/${room.id}`), {
    id: room.id,
    hostUserID: room.hostUserID,
    usersIn: room.usersIn.map((user) => ({
      ...user,
      points: 0,
      ownTraps: shuffle(Object.keys(C.trapList)).splice(0, 4),
    })),
  });

// Custom requests

export const changeAvatarId = (
  room: IRoom,
  userID: string,
  avatarId: number,
) => {
  const userIndex = room.usersIn.findIndex((user) => user.id === userID);

  set(ref(db, `/rooms/${room.id}/usersIn/${userIndex}/avatarID`), avatarId);
};

export const setReadyPlayer = (userID: string, room: IRoom) => {
  const userIndex = room.usersIn.findIndex((user) => user.id === userID);

  set(ref(db, `/rooms/${room.id}/usersIn/${userIndex}/isReady`), true);
};

export const setTrapForUser = async (
  userID: string,
  trap: Traps,
  userFromID: string,
  room: IRoom,
) => {
  if (trap === "clean") {
    await set(
      ref(db, `/rooms/${room.id}/currentQuestion/userTraps/${userID}`),
      {},
    );
  } else {
    await set(
      ref(db, `/rooms/${room.id}/currentQuestion/userTraps/${userID}/${trap}`),
      {
        userFromID,
      },
    );
  }

  const userIndex = room.usersIn.findIndex((user) => user.id === userFromID);
  const ownTraps = room?.usersIn?.[userIndex]?.ownTraps?.filter(
    (item) => item !== trap,
  );

  await set(
    ref(db, `/rooms/${room.id}/usersIn/${userIndex}/ownTraps`),
    ownTraps,
  );
};
